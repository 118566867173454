import * as THREE from "three";

function SquarePocket({ start = [0, 0], ...props }) {
  if (!props.shouldDisplay) {
    return <mesh />;
  }

  const pocketWidth = props.width - 0.08;
  const maxPocketHeight =
    props.height - props.bottomCornerRadius - props.topCornerRadius;
  const standardPocketHeight = 0.1;

  let pocketHeight;

  if (maxPocketHeight < standardPocketHeight) {
    pocketHeight = maxPocketHeight;
  } else {
    pocketHeight = standardPocketHeight;
  }

  const pocketShape = new THREE.Shape();
  pocketShape.moveTo(-pocketWidth / 2, 0.02);
  pocketShape.lineTo(pocketWidth / 2, 0.02);
  pocketShape.lineTo(pocketWidth / 2, pocketHeight + 0.02);
  pocketShape.lineTo(-pocketWidth / 2, pocketHeight + 0.02);
  pocketShape.lineTo(-pocketWidth / 2, 0.02);

  const extrudeSettings = {
    steps: 2,
    depth: 0.1,
    bevelEnabled: true,
    bevelThickness: 0.02,
    bevelSize: 0.02,
    bevelOffset: 0,
    bevelSegments: 5,
  };

  const pocketGeometry = new THREE.ExtrudeGeometry(
    pocketShape,
    extrudeSettings
  );
  const pocketEdges = new THREE.EdgesGeometry(pocketGeometry, 45);

  return (
    <group position={props.position} rotation={props.rotation} dispose={null}>
      <mesh geometry={pocketGeometry}>
        <meshStandardMaterial color="#0000FF" />
      </mesh>
      <lineSegments geometry={pocketEdges}>
        <lineBasicMaterial color="black" depthTest={true} />
      </lineSegments>
    </group>
  );
}

export default SquarePocket;
