import "./Header.scss";

function Header() {
  return (
    <header className="site-header">
      <div className="site-header__container">
        <h1 className="site-header__title">MYOP</h1>
      </div>
    </header>
  );
}

export default Header;
