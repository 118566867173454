import * as THREE from "three";

function PackBody({ start = [0, 0], ...props }) {
  const {
    topLeftLow,
    topRightLow,
    bottomLeftLow,
    bottomRightLow,
    topCornerRadius,
    bottomCornerRadius,
  } = props;

  const panelShape = new THREE.Shape();

  panelShape.moveTo(0, 0);
  panelShape.lineTo(bottomRightLow[0], bottomRightLow[1]);
  // bottom right arc:
  panelShape.arc(0, bottomCornerRadius, bottomCornerRadius, -1.57, 0, false);
  // right vertical side:
  panelShape.lineTo(topRightLow[0], topRightLow[1]);
  // top right arc:
  panelShape.arc(-topCornerRadius, 0, topCornerRadius, 0, 1.57, false);
  // top horizontal:
  panelShape.lineTo(topLeftLow[0], topLeftLow[1]);
  // top left arc:
  panelShape.arc(0, -topCornerRadius, topCornerRadius, 1.57, -3.142, false);
  // left vertical:
  panelShape.lineTo(bottomLeftLow[0], bottomLeftLow[1]);
  // bottom right arc:
  panelShape.arc(
    bottomCornerRadius,
    0,
    bottomCornerRadius,
    -3.142,
    -1.57,
    false
  );
  // bottom horizontal:
  panelShape.lineTo(0, 0);

  const panelGeometry = new THREE.ExtrudeGeometry(panelShape, props);
  const panelEdges = new THREE.EdgesGeometry(panelGeometry, 45);

  return (
    <group position={props.position} dispose={null}>
      <mesh geometry={panelGeometry}>
        <meshStandardMaterial color={props.color} />
      </mesh>
      <lineSegments geometry={panelEdges}>
        <lineBasicMaterial color="black" depthTest={true} />
      </lineSegments>
    </group>
  );
}

export default PackBody;
