import * as THREE from "three";

function PackStrap({ start = [0, 0], ...props }) {
  if (!props.shouldDisplay) {
    return <mesh />;
  }

  const strapHeight = props.height - 0.05;

  const strapShape = new THREE.Shape();
  strapShape.bezierCurveTo(0.1, -0.05, 0.06, -0.2, 0.04, -strapHeight + 0.04);
  strapShape.lineTo(0.035, -strapHeight + 0.04);
  strapShape.bezierCurveTo(0.055, -0.2, 0.095, -0.05, 0, -0.005);
  strapShape.lineTo(0, 0);

  const strapGeometry = new THREE.ExtrudeGeometry(strapShape, props);
  const strapEdges = new THREE.EdgesGeometry(strapGeometry, 45);

  return (
    <group position={props.position} rotation={props.rotation} dispose={null}>
      <mesh geometry={strapGeometry}>
        <meshStandardMaterial />
      </mesh>
      <lineSegments geometry={strapEdges}>
        <lineBasicMaterial color="black" depthTest={true} />
      </lineSegments>
    </group>
  );
}

export default PackStrap;
