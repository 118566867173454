import CONSTANTS from "../../../constants";

const sidePanelSvg = ({ patternParameters }) => {
  const { innerHeight, innerWidthTop, topCornerRadius, depth } =
    patternParameters;

  const svgPaths = [];
  const svgText = [];

  const sidePanelLength =
    2 * innerHeight + innerWidthTop + Math.PI * topCornerRadius;
  const sidePanelLengthSA = sidePanelLength + 2 * CONSTANTS.seamAllowancePt;
  const sidePanelDepth = depth / 2;
  const sidePanelDepthSA = sidePanelDepth + 2 * CONSTANTS.seamAllowancePt;

  // Seam Allowance
  svgPaths.push(
    [
      `m 0 0`,
      `l 0 ${sidePanelLengthSA}`,
      `l ${sidePanelDepthSA} 0`,
      `l 0 -${sidePanelLengthSA}`,
      `l -${sidePanelDepthSA} 0`,
    ].join(" ")
  );

  // Main shape
  svgPaths.push(
    [
      `m ${CONSTANTS.seamAllowancePt} ${CONSTANTS.seamAllowancePt}`,
      `l 0 ${sidePanelLength}`,
      `l ${sidePanelDepth} 0`,
      `l 0 -${sidePanelLength}`,
      `l -${sidePanelDepth} 0`,
    ].join(" ")
  );

  // label text
  svgText.push({
    x: sidePanelDepthSA / 2,
    y: sidePanelLengthSA / 2,
    label: "Side Panel",
    verticalAlign: true,
  });

  return {
    svgPaths,
    svgText,
    maxHeight: sidePanelLengthSA,
    maxWidth: sidePanelDepthSA,
  };
};

export default sidePanelSvg;
