import {
  PDFDownloadLink,
  Document,
  Page,
  StyleSheet,
  Svg,
  G,
  Path,
  Text,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: { padding: 18 },
  image: { border: "1 dashed black", margin: 18 },
  label: { font: "35px arial" },
});

const SvgTile = ({
  index,
  tileSetting,
  rowCount,
  colCount,
  svgPaths,
  svgText,
}) => {
  const pageLabelX = (colCount - 1) * 576 + 10;
  const pageLabelY = (rowCount - 1) * 756 + 20;
  return (
    <Svg viewBox={tileSetting} width="576" height="756" style={styles.image}>
      <Text
        x={`${pageLabelX}`}
        y={`${pageLabelY}`}
        style={{ fontSize: "10px", fill: "grey" }}
      >{`Row: ${rowCount}, Column: ${colCount}`}</Text>
      <G>
        {svgPaths.map((path) => (
          <Path d={path} stroke="black" strokeWidth="2" fill="none" />
        ))}
      </G>
      {svgText.map((text) => (
        <G style={{ transform: `translate(${text.x}, ${text.y})` }}>
          <Text
            {...(text.verticalAlign
              ? { style: { fontSize: "35px", transform: "rotate(-90deg)" } }
              : { style: { fontSize: "35px" } })}
            textAnchor="middle"
          >
            {text.label}
          </Text>
        </G>
      ))}
    </Svg>
  );
};

const PdfDoc = ({ svgPaths, svgText, maxSvgWidth, maxSvgHeight }) => {
  const tileSettings = [];

  const tileWidth = 576;
  const tileHeight = 756;

  const numPagesWide = Math.ceil(maxSvgWidth / tileWidth);
  const numPagesHigh = Math.ceil(maxSvgHeight / tileHeight);

  for (let i = 0; i < numPagesHigh; i++) {
    for (let j = 0; j < numPagesWide; j++) {
      const tileSetting = `${j * tileWidth} ${
        i * tileHeight
      } ${tileWidth} ${tileHeight}`;
      tileSettings.push({ tileSetting, rowCount: i + 1, colCount: j + 1 });
    }
  }

  return (
    <Document>
      {tileSettings.map((setting, index) => (
        <Page key={index} style={styles.body} size="letter">
          <SvgTile
            index={index}
            tileSetting={setting.tileSetting}
            rowCount={setting.rowCount}
            colCount={setting.colCount}
            svgPaths={svgPaths}
            svgText={svgText}
          />
        </Page>
      ))}
    </Document>
  );
};

function PdfGenerator({ svgPaths, svgText, maxSvgWidth, maxSvgHeight }) {
  return (
    <PDFDownloadLink
      className="preview-pattern__download-option"
      document={
        <PdfDoc
          svgPaths={svgPaths}
          svgText={svgText}
          maxSvgWidth={maxSvgWidth}
          maxSvgHeight={maxSvgHeight}
        />
      }
      fileName="pattern.pdf"
    >
      {({ blob, url, loading, error }) =>
        loading ? "Download Pattern" : "Download Pattern"
      }
    </PDFDownloadLink>
  );
}

export default PdfGenerator;
