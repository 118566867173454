import * as THREE from "three";

function VerticalPocket({ start = [0, 0], ...props }) {
  if (!props.shouldDisplay) {
    return <mesh />;
  }

  const {
    topLeftLow,
    bottomLeftLow,
    bottomRightLow,
    topCornerRadius,
    bottomCornerRadius,
    height,
    width,
  } = props;
  let zipperTopPos = [width / 4, height];
  let zipperBottomPos = [width / 4, 0];

  const pocketShape = new THREE.Shape();

  pocketShape.moveTo(0, 0);

  // bottom right corner:
  if (width / 4 < bottomRightLow[0]) {
    pocketShape.lineTo(width / 4, 0);
  } else {
    pocketShape.lineTo(bottomRightLow[0], bottomRightLow[1]);
    // bottom right arc:
    const bottomOpp = width / 4 - bottomRightLow[0];
    const bottomTheta = Math.asin(bottomOpp / bottomCornerRadius);
    const bottomAdj = Math.sqrt(
      bottomCornerRadius * bottomCornerRadius - bottomOpp * bottomOpp
    );
    const zipperstartY = bottomCornerRadius - bottomAdj;
    zipperBottomPos[1] = zipperstartY;
    pocketShape.arc(
      0,
      bottomCornerRadius,
      bottomCornerRadius,
      -1.57,
      bottomTheta - 1.57,
      false
    );
  }

  // top right corner:
  if (width / 4 < width / 2 - topCornerRadius) {
    pocketShape.lineTo(width / 4, height);
  } else {
    const topOpp = width / 4 - (width / 2 - topCornerRadius);
    const topTheta = Math.asin(topOpp / topCornerRadius);
    const arcStart = 1.571 - topTheta;
    const topAdj = Math.sqrt(
      topCornerRadius * topCornerRadius - topOpp * topOpp
    );
    const zipperEndY = height - (topCornerRadius - topAdj);
    zipperTopPos[1] = zipperEndY;

    pocketShape.lineTo(width / 4, zipperEndY);
    pocketShape.arc(-topOpp, -topAdj, topCornerRadius, arcStart, 1.57, false);
  }

  // top horizontal:
  pocketShape.lineTo(topLeftLow[0], topLeftLow[1]);
  // top left arc:
  pocketShape.arc(0, -topCornerRadius, topCornerRadius, 1.57, -3.142, false);
  // left vertical:
  pocketShape.lineTo(bottomLeftLow[0], bottomLeftLow[1]);
  // bottom right arc:
  pocketShape.arc(
    bottomCornerRadius,
    0,
    bottomCornerRadius,
    -3.142,
    -1.57,
    false
  );
  // bottom horizontal:
  pocketShape.lineTo(0, 0);

  const extrudeSettings = {
    steps: 2,
    depth: 0.01,
    bevelEnabled: false,
  };

  const pocketGeometry = new THREE.ExtrudeGeometry(
    pocketShape,
    extrudeSettings
  );
  const pocketEdges = new THREE.EdgesGeometry(pocketGeometry, 45);

  const pocketZipperShape = new THREE.Shape();

  pocketZipperShape.moveTo(0, zipperBottomPos[1]);
  pocketZipperShape.lineTo(0, zipperTopPos[1]);
  pocketZipperShape.lineTo(0.01, zipperTopPos[1]);
  pocketZipperShape.lineTo(0.01, zipperBottomPos[1]);
  pocketZipperShape.lineTo(0, zipperBottomPos[1]);

  const pocketZipperGeometry = new THREE.ShapeGeometry(pocketZipperShape);

  return (
    <group position={props.position} dispose={null}>
      <mesh geometry={pocketGeometry}>
        <meshStandardMaterial />
      </mesh>
      <lineSegments geometry={pocketEdges}>
        <lineBasicMaterial color="black" depthTest={true} />
      </lineSegments>
      <mesh
        geometry={pocketZipperGeometry}
        rotation={[0, -Math.PI / 2, 0]}
        position={[width / 4 + 0.0001, 0, 0]}
      >
        <meshStandardMaterial side={THREE.DoubleSide} color="#000000" />
      </mesh>
    </group>
  );
}

export default VerticalPocket;
