import CONSTANTS from "../../../constants";

const dropPocketsSvg = ({ patternParameters }) => {
  const { width, innerHeight } = patternParameters;

  const svgPaths = [];
  const svgText = [];

  const foldLength = 4 * CONSTANTS.cmToPt;
  const dropPocketWidth = width + 4 * foldLength;
  const dropPocketWidthSA = dropPocketWidth + 2 * CONSTANTS.seamAllowancePt;
  const dropPocketHeight = 0.75 * innerHeight;
  const dropPocketHeightSA = dropPocketHeight + CONSTANTS.seamAllowancePt;

  // Seam Allowance
  svgPaths.push(
    [
      `m 0 0`,
      `l 0 ${dropPocketHeightSA}`,
      `l ${dropPocketWidthSA} 0`,
      `l 0 -${dropPocketHeightSA}`,
      `l -${dropPocketWidthSA} 0`,
    ].join(" ")
  );

  // Main shape
  svgPaths.push(
    [
      `m ${CONSTANTS.seamAllowancePt} 0`,
      `l 0 ${dropPocketHeight} l ${dropPocketWidth} 0`,
      `l 0 -${dropPocketHeight}`,
      `l -${dropPocketWidth} 0`,
    ].join(" ")
  );

  // label text - top
  svgText.push({
    x: dropPocketWidthSA / 2,
    y: dropPocketHeightSA / 2,
    label: "Drop Pockets",
  });

  return {
    svgPaths,
    svgText,
    maxHeight: dropPocketHeightSA,
    maxWidth: dropPocketWidthSA,
  };
};

export default dropPocketsSvg;
