import * as THREE from "three";

function PackBody({ start = [0, 0], ...props }) {
  const { bottomLeftLow, bottomRightLow, bottomCornerRadius } = props;
  const panelThickness = 0.001;
  const outerRadius = bottomCornerRadius + panelThickness;
  const bottomRightEndArcOuter = [
    bottomRightLow[0] + outerRadius,
    bottomRightLow[1] + bottomCornerRadius,
  ];
  const bottomLeftStartArcOuter = [
    bottomLeftLow[0] + bottomCornerRadius,
    bottomLeftLow[1] - outerRadius,
  ];

  const panelShape = new THREE.Shape();

  panelShape.moveTo(0, 0);
  panelShape.lineTo(bottomRightLow[0], bottomRightLow[1]);
  // bottom right arc:
  panelShape.arc(0, bottomCornerRadius, bottomCornerRadius, -1.57, 0, false);
  // add thickness
  panelShape.lineTo(bottomRightEndArcOuter[0], bottomRightEndArcOuter[1]);
  // right arc back
  panelShape.arc(-outerRadius, 0, outerRadius, 0, -1.57, true);
  // outer line to left arc
  panelShape.lineTo(bottomLeftStartArcOuter[0], bottomLeftStartArcOuter[1]);
  // outer left arc
  panelShape.arc(0, outerRadius, outerRadius, -1.57, -3.142, true);
  // remove thickness
  panelShape.lineTo(bottomLeftLow[0], bottomLeftLow[1]);
  // left arc inner
  panelShape.arc(
    bottomCornerRadius,
    0,
    bottomCornerRadius,
    -3.142,
    -1.57,
    false
  );

  const panelGeometry = new THREE.ExtrudeGeometry(panelShape, props);
  const panelEdges = new THREE.EdgesGeometry(panelGeometry, 45);

  return (
    <group position={props.position} dispose={null}>
      <mesh geometry={panelGeometry}>
        <meshStandardMaterial />
      </mesh>
      <lineSegments geometry={panelEdges}>
        <lineBasicMaterial color="black" depthTest={true} />
      </lineSegments>
    </group>
  );
}

export default PackBody;
