const CONSTANTS = {
  cmToPt: 28.3465,
  mToPt: 2834.65,
  seamAllowanceCm: 1,
  seamAllowancePt: 28.3465,
  testBackpackParams: {
    height: 0.46,
    width: 0.24,
    depth: 0.16,
    topCornerRadius: 0.12,
    bottomCornerRadius: 0.05,
    frontPocket: "vertical",
    selectLaptopSleeve: true,
    selectDumpPockets: true,
  },
};

export default CONSTANTS;
