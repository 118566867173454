import "./EditorForm.scss";

function EditorForm({ projectParameters = {}, handleChange }) {
  const {
    height = 0.46,
    width = 0.24,
    depth = 0.16,
    topCornerRadius = 0.12,
    bottomCornerRadius = 0.05,
    frontPocket = "vertical",
    selectDumpPockets = true,
    selectLaptopSleeve = true,
  } = projectParameters;

  return (
    <div className="editor">
      <form className="editor__form">
        <div className="editor__options-group">
          <h2 className="editor__heading">Dimensions</h2>
          <div className="editor__dimension-container">
            <div className="editor__label-container">
              <label className="editor__label" htmlFor="height">
                Height:
              </label>
              <span className="editor__value">
                {Math.round(height * 100)} cm
              </span>
            </div>
            <input
              className="editor__slider"
              type="range"
              max={0.6}
              min={0.3}
              step={0.01}
              name="height"
              id="height"
              value={height}
              onChange={handleChange}
            />
          </div>
          <div className="editor__dimension-container">
            <div className="editor__label-container">
              <label className="editor__label" htmlFor="width">
                Width:
              </label>
              <span className="editor__value">
                {Math.round(width * 100)} cm
              </span>
            </div>
            <input
              className="editor__slider"
              type="range"
              max={0.35}
              min={0.2}
              step={0.01}
              name="width"
              id="width"
              value={width}
              onChange={handleChange}
            />
          </div>
          <div className="editor__dimension-container">
            <div className="editor__label-container">
              <label className="editor__label" htmlFor="depth">
                Depth:
              </label>
              <span className="editor__value">
                {Math.round(depth * 100)} cm
              </span>
            </div>
            <input
              className="editor__slider"
              type="range"
              max={0.3}
              min={0.1}
              step={0.01}
              name="depth"
              id="depth"
              value={depth}
              onChange={handleChange}
            />
          </div>
          <div className="editor__dimension-container">
            <div className="editor__label-container">
              <label className="editor__label" htmlFor="topCornerRadius">
                Top Corners:
              </label>
              <span className="editor__value">
                {Math.round(topCornerRadius * 100)} cm
              </span>
            </div>
            <input
              className="editor__slider"
              type="range"
              max={0.175}
              min={0.04}
              step={0.01}
              name="topCornerRadius"
              id="topCornerRadius"
              value={topCornerRadius}
              onChange={handleChange}
            />
          </div>
          <div className="editor__dimension-container">
            <div className="editor__label-container">
              <label className="editor__label" htmlFor="bottomCornerRadius">
                Base Corners:
              </label>
              <span className="editor__value">
                {Math.round(bottomCornerRadius * 100)} cm
              </span>
            </div>
            <input
              className="editor__slider"
              type="range"
              max={0.1}
              min={0.02}
              step={0.01}
              name="bottomCornerRadius"
              id="bottomCornerRadius"
              value={bottomCornerRadius}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="editor__options-group">
          <h2 className="editor__heading">Front Pocket</h2>
          <div className="editor__selection-container">
            <label className="editor__label" htmlFor="none">
              None
            </label>
            <input
              className="editor__radio-button"
              name="frontPocket"
              type="radio"
              value="none"
              id="none"
              checked={frontPocket === "none"}
              onChange={handleChange}
            />
          </div>
          <div className="editor__selection-container">
            <label className="editor__label" htmlFor="square">
              Square
            </label>
            <input
              className="editor__radio-button"
              name="frontPocket"
              type="radio"
              value="square"
              id="square"
              checked={frontPocket === "square"}
              onChange={handleChange}
            />
          </div>
          <div className="editor__selection-container">
            <label className="editor__label" htmlFor="horizontal">
              Horizontal
            </label>
            <input
              className="editor__radio-button"
              name="frontPocket"
              type="radio"
              value="horizontal"
              id="horizontal"
              checked={frontPocket === "horizontal"}
              onChange={handleChange}
            />
          </div>
          <div className="editor__selection-container">
            <label className="editor__label" htmlFor="vertical">
              Vertical
            </label>
            <input
              className="editor__radio-button"
              name="frontPocket"
              type="radio"
              value="vertical"
              id="vertical"
              checked={frontPocket === "vertical"}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="editor__options-group">
          <h2 className="editor__heading">Internal Features</h2>
          <div className="editor__selection-container">
            <label className="editor__label" htmlFor="selectLaptopSleeve">
              Laptop Sleeve
            </label>
            <input
              className="editor__radio-button"
              type="checkbox"
              name="selectLaptopSleeve"
              id="selectLaptopSleeve"
              checked={selectLaptopSleeve}
              onChange={handleChange}
            />
          </div>
          <div className="editor__selection-container">
            <label className="editor__label" htmlFor="selectDumpPockets">
              Drop Pockets
            </label>
            <input
              className="editor__radio-button"
              type="checkbox"
              name="selectDumpPockets"
              id="selectDumpPockets"
              checked={selectDumpPockets}
              onChange={handleChange}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditorForm;
