import "./PreviewModel.scss";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import modelParameters from "./modelParameters";
import PackBody from "../../threeJsComponents/PackBody";
import PackStrap from "../../threeJsComponents/PackStrap";
import SquarePocket from "../../threeJsComponents/SquarePocket";
import HorizontalPocket from "../../threeJsComponents/HorizontalPocket";
import VerticalPocket from "../../threeJsComponents/VerticalPocket";
import OpenPocket from "../../threeJsComponents/OpenPocket";
import BasePanel from "../../threeJsComponents/BasePanel";

function PreviewModel({ projectParameters, handleChangeViewMode, viewMode }) {
  const {
    height,
    width,
    depth,
    topCornerRadius,
    bottomCornerRadius,
    frontPocket,
    selectLaptopSleeve,
    selectDumpPockets,
  } = projectParameters;

  const {
    bottomRightLow,
    topRightLow,
    topLeftLow,
    bottomLeftLow,
    sidePanelDepth,
  } = modelParameters(projectParameters);

  let flipView = viewMode === "exterior" ? "interior" : "exterior";

  return (
    <div className="preview-model">
      <div className="preview-model__canvas-container">
        <Canvas
          className="preview-model__canvas"
          camera={{ position: [0, 0.2, 0.7] }}
        >
          <OrbitControls />
          <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
          <pointLight position={[-10, -10, -10]} />

          <group rotation={[0, -Math.PI / 6, 0]} position={[0, -height / 2, 0]}>
            <PackBody
              position={[0, 0, -depth / 2]}
              color="#FFFFFF"
              depth={sidePanelDepth}
              bevelEnabled={false}
              height={height}
              width={width}
              topCornerRadius={topCornerRadius}
              bottomCornerRadius={bottomCornerRadius}
              bottomRightLow={bottomRightLow}
              topRightLow={topRightLow}
              topLeftLow={topLeftLow}
              bottomLeftLow={bottomLeftLow}
            />

            <PackBody
              position={[0, 0, 0.005]}
              color="#FFFFFF"
              depth={sidePanelDepth}
              bevelEnabled={false}
              height={height}
              width={width}
              topCornerRadius={topCornerRadius}
              bottomCornerRadius={bottomCornerRadius}
              bottomRightLow={bottomRightLow}
              topRightLow={topRightLow}
              topLeftLow={topLeftLow}
              bottomLeftLow={bottomLeftLow}
            />

            <PackBody
              position={[0, 0, -0.005]}
              color="#000000"
              depth={0.01}
              bevelEnabled={false}
              height={height}
              width={width}
              topCornerRadius={topCornerRadius}
              bottomCornerRadius={bottomCornerRadius}
              bottomRightLow={bottomRightLow}
              topRightLow={topRightLow}
              topLeftLow={topLeftLow}
              bottomLeftLow={bottomLeftLow}
            />

            <BasePanel
              position={[0, 0, -depth / 2]}
              depth={depth}
              bevelEnabled={false}
              height={height}
              width={width}
              bottomCornerRadius={bottomCornerRadius}
              bottomRightLow={bottomRightLow}
              bottomLeftLow={bottomLeftLow}
            />

            <PackStrap
              depth={0.05}
              height={height}
              position={[0.035, height - 0.05, -depth / 2]}
              rotation={[0, Math.PI / 2, 0]}
              bevelEnabled={false}
              shouldDisplay={viewMode === "exterior"}
            />

            <PackStrap
              depth={0.05}
              height={height}
              position={[-0.05 - 0.035, height - 0.05, -depth / 2]}
              rotation={[0, Math.PI / 2, 0]}
              bevelEnabled={false}
              shouldDisplay={viewMode === "exterior"}
            />

            <SquarePocket
              position={[0, 0.75 * bottomCornerRadius, depth / 2 - 0.08]}
              rotation={[0, 0, 0]}
              topCornerRadius={topCornerRadius}
              width={width}
              shouldDisplay={
                viewMode === "exterior" && frontPocket === "square"
              }
            />

            <HorizontalPocket
              position={[0, 0, depth / 2]}
              rotation={[0, 0, 0]}
              height={height}
              width={width}
              topCornerRadius={topCornerRadius}
              bottomCornerRadius={bottomCornerRadius}
              bottomRightLow={bottomRightLow}
              topRightLow={topRightLow}
              topLeftLow={topLeftLow}
              bottomLeftLow={bottomLeftLow}
              shouldDisplay={
                viewMode === "exterior" && frontPocket === "horizontal"
              }
            />

            <VerticalPocket
              position={[0, 0, depth / 2]}
              rotation={[0, 0, 0]}
              height={height}
              width={width}
              topCornerRadius={topCornerRadius}
              bottomCornerRadius={bottomCornerRadius}
              bottomRightLow={bottomRightLow}
              topRightLow={topRightLow}
              topLeftLow={topLeftLow}
              bottomLeftLow={bottomLeftLow}
              shouldDisplay={
                viewMode === "exterior" && frontPocket === "vertical"
              }
            />

            <OpenPocket
              position={[0, bottomCornerRadius, -depth / 2]}
              rotation={[-Math.PI / 2, 0, -Math.PI]}
              maxHeight={height - topCornerRadius - bottomCornerRadius}
              width={width}
              depth={0.04}
              shouldDisplay={viewMode === "interior" && selectLaptopSleeve}
            />
            <OpenPocket
              position={[width / 4, bottomCornerRadius, depth / 2]}
              rotation={[-Math.PI / 2, 0, 0]}
              maxHeight={0.75 * (height - topCornerRadius - bottomCornerRadius)}
              width={width / 2}
              depth={0.04}
              shouldDisplay={viewMode === "interior" && selectDumpPockets}
            />
            <OpenPocket
              position={[-width / 4, bottomCornerRadius, depth / 2]}
              rotation={[-Math.PI / 2, 0, 0]}
              maxHeight={0.75 * (height - topCornerRadius - bottomCornerRadius)}
              width={width / 2}
              depth={0.04}
              shouldDisplay={viewMode === "interior" && selectDumpPockets}
            />
          </group>
        </Canvas>
      </div>
      <div className="preview-model__footer">
        <span
          className="preview-model__view-option"
          onClick={() => {
            handleChangeViewMode(flipView);
          }}
        >
          Switch To {viewMode === "exterior" ? "Interior" : "Exterior"} View
        </span>
      </div>
    </div>
  );
}

export default PreviewModel;
