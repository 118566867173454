import * as THREE from "three";

function OpenPocket({ start = [0, 0], ...props }) {
  if (!props.shouldDisplay) {
    return <mesh />;
  }

  const { maxHeight, width, depth } = props;
  const pocketThickness = 0.003;

  const pocketBase = new THREE.Shape();
  pocketBase.moveTo(-width / 2, 0);
  pocketBase.lineTo(width / 2, 0);
  pocketBase.bezierCurveTo(
    width * 0.25,
    -depth,
    -width * 0.25,
    -depth,
    -width / 2,
    0
  );

  const baseGeometry = new THREE.ShapeGeometry(pocketBase);
  const baseEdges = new THREE.EdgesGeometry(baseGeometry, 45);

  const pocketPanel = new THREE.Shape();
  pocketPanel.moveTo(width / 2, 0);
  pocketPanel.bezierCurveTo(
    width * 0.25,
    -depth,
    -width * 0.25,
    -depth,
    -width / 2,
    0
  );
  pocketPanel.lineTo(-width / 2 + pocketThickness, 0);
  pocketPanel.bezierCurveTo(
    -width * 0.25,
    -depth + pocketThickness,
    width * 0.25,
    -depth + pocketThickness,
    width / 2 - pocketThickness,
    0
  );
  pocketPanel.lineTo(width / 2, 0);

  const extrudeSettings = {
    steps: 2,
    depth: maxHeight,
    bevelEnabled: false,
  };

  const panelGeometry = new THREE.ExtrudeGeometry(pocketPanel, extrudeSettings);
  const panelEdges = new THREE.EdgesGeometry(panelGeometry, 45);

  return (
    <group position={props.position} rotation={props.rotation} dispose={null}>
      <mesh geometry={panelGeometry}>
        <meshStandardMaterial />
      </mesh>
      <lineSegments geometry={panelEdges}>
        <lineBasicMaterial color="black" depthTest={true} />
      </lineSegments>
      <mesh geometry={baseGeometry}>
        <meshStandardMaterial side={THREE.DoubleSide} />
      </mesh>
      <lineSegments geometry={baseEdges}>
        <lineBasicMaterial color="black" depthTest={true} />
      </lineSegments>
    </group>
  );
}

export default OpenPocket;
