import CONSTANTS from "../../../constants";

const basePanelSvg = ({ patternParameters }) => {
  const { depthSA, depth, innerWidthBottom, bottomCornerRadius } =
    patternParameters;

  const svgPaths = [];
  const svgText = [];

  const baseLength = innerWidthBottom + Math.PI * bottomCornerRadius;
  const baseLengthSA = baseLength + 2 * CONSTANTS.seamAllowancePt;

  // Seam Allowance
  svgPaths.push(
    [
      `m 0 0 `,
      `l ${depthSA} 0`,
      `l 0 -${baseLengthSA}`,
      `l -${depthSA} 0`,
      `l 0 ${baseLengthSA}`,
    ].join(" ")
  );

  // Main shape
  svgPaths.push(
    [
      `m ${CONSTANTS.seamAllowancePt} ${-CONSTANTS.seamAllowancePt}`,
      `l ${depth} 0`,
      `l 0 -${baseLength}`,
      `l -${depth} 0`,
      `l 0 ${baseLength}`,
    ].join(" ")
  );

  // label text
  svgText.push({
    x: depthSA / 2,
    y: -baseLengthSA / 2,
    label: "Base Panel",
  });

  return {
    svgPaths,
    svgText,
    maxHeight: baseLengthSA,
    maxWidth: depthSA,
  };
};

export default basePanelSvg;
