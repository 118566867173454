import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.scss";
import Header from "./reactComponents/Header";
import EditPage from "./pages/EditPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Switch>
          <Route path="/" exact render={() => <EditPage />} />
          <Route render={() => <Redirect to={{ pathname: "/" }} />} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
