function modelParameters(projectParameters) {
  const { height, width, depth, topCornerRadius, bottomCornerRadius } =
    projectParameters;

  const modelParameters = {
    bottomRightLow: [width / 2 - bottomCornerRadius, 0],
    topRightLow: [width / 2, height - topCornerRadius],
    topLeftLow: [-width / 2 + topCornerRadius, height],
    bottomLeftLow: [-width / 2, bottomCornerRadius],
    sidePanelDepth: (depth - 0.01) / 2,
  };

  return modelParameters;
}
export default modelParameters;
