import backpackPatternParameters from "./patternParamHelper";

import basePanelSvg from "./backpackComponents/basePanel";
import bodyPanelSvg from "./backpackComponents/bodyPanel";
import dropPocketsSvg from "./backpackComponents/dropPockets";
import horizontalPocketSvg from "./backpackComponents/horizontalPocket";
import laptopSleeveSvg from "./backpackComponents/laptopSleeve";
import shoulderStrapSvg from "./backpackComponents/shoulderStrap";
import sidePanelSvg from "./backpackComponents/sidePanel";
import squarePocketSvg from "./backpackComponents/squarePocket";
import verticalPocketSvg from "./backpackComponents/verticalPocket";

function backpackPatternSvg(projectParameters) {
  const { width, frontPocket, selectLaptopSleeve, selectDumpPockets } =
    projectParameters;

  const patternParameters = backpackPatternParameters(projectParameters);

  // add each pattern component to this object then iterate to get all svg paths
  const svgComponents = {};

  const translate = (comp, x, y) => {
    for (let i = 0; i < comp.svgPaths.length; i++) {
      comp.svgPaths[i] = `M ${x} ${y}` + comp.svgPaths[i];
    }
    for (let i = 0; i < comp.svgText.length; i++) {
      comp.svgText[i].x += x;
      comp.svgText[i].y += y;
    }
  };

  svgComponents.sidePanel = sidePanelSvg({ patternParameters });
  translate(
    svgComponents.sidePanel,
    patternParameters.spacing,
    patternParameters.spacing
  );

  svgComponents.bodyPanel = bodyPanelSvg({ patternParameters });
  svgComponents.basePanel = basePanelSvg({ patternParameters });

  // determine if basePanel or bodyPanel is tallest
  // Use this to set Y start for both panels
  // shoulderStrap and frontPocket also anchored to this
  const bodyBaseStartY =
    patternParameters.spacing +
    (svgComponents.bodyPanel.maxHeight > svgComponents.basePanel.maxHeight
      ? svgComponents.bodyPanel.maxHeight
      : svgComponents.basePanel.maxHeight);

  translate(
    svgComponents.bodyPanel,
    svgComponents.sidePanel.maxWidth +
      2 * patternParameters.spacing +
      svgComponents.bodyPanel.maxWidth / 2,
    bodyBaseStartY
  );
  translate(
    svgComponents.basePanel,
    3 * patternParameters.spacing +
      svgComponents.sidePanel.maxWidth +
      svgComponents.bodyPanel.maxWidth,
    bodyBaseStartY
  );

  svgComponents.shoulderStrap = shoulderStrapSvg({ patternParameters });
  translate(
    svgComponents.shoulderStrap,
    svgComponents.sidePanel.maxWidth + 2 * patternParameters.spacing,
    bodyBaseStartY + patternParameters.spacing
  );

  if (frontPocket === "square") {
    svgComponents.frontPocket = squarePocketSvg({ patternParameters });
    translate(
      svgComponents.frontPocket,
      3 * patternParameters.spacing +
        svgComponents.sidePanel.maxWidth +
        svgComponents.shoulderStrap.maxWidth,
      bodyBaseStartY + patternParameters.spacing
    );
  }

  if (frontPocket === "horizontal") {
    svgComponents.frontPocket = horizontalPocketSvg({ patternParameters });
    translate(
      svgComponents.frontPocket,
      3 * patternParameters.spacing +
        svgComponents.sidePanel.maxWidth +
        svgComponents.shoulderStrap.maxWidth,
      bodyBaseStartY + patternParameters.spacing
    );
  }

  if (frontPocket === "vertical") {
    svgComponents.frontPocket = verticalPocketSvg({ patternParameters });
    translate(
      svgComponents.frontPocket,
      3 * patternParameters.spacing +
        svgComponents.sidePanel.maxWidth +
        svgComponents.shoulderStrap.maxWidth +
        svgComponents.frontPocket.maxWidth -
        width / 4,
      bodyBaseStartY + patternParameters.spacing
    );
  }

  // set Y start point for laptopSleeve and dropPockets
  const laptopDropStartY =
    2 * patternParameters.spacing +
    Math.max(
      svgComponents.sidePanel.maxHeight,
      bodyBaseStartY + svgComponents.shoulderStrap.maxHeight,
      bodyBaseStartY + (svgComponents.frontPocket?.maxHeight || 0)
    );

  let dropPocketsStartX = patternParameters.spacing;
  let totalHeight = laptopDropStartY + patternParameters.spacing;

  if (selectLaptopSleeve) {
    svgComponents.laptopSleeve = laptopSleeveSvg({ patternParameters });
    translate(
      svgComponents.laptopSleeve,
      patternParameters.spacing,
      laptopDropStartY
    );

    dropPocketsStartX +=
      svgComponents.laptopSleeve.maxWidth + patternParameters.spacing;
    totalHeight += svgComponents.laptopSleeve.maxHeight;
  }

  if (selectDumpPockets) {
    svgComponents.dropPockets = dropPocketsSvg({ patternParameters });
    translate(svgComponents.dropPockets, dropPocketsStartX, laptopDropStartY);

    if (!selectLaptopSleeve) {
      totalHeight += svgComponents.dropPockets.maxHeight;
    }
  }

  const totalWidth = Math.max(
    4 * patternParameters.spacing +
      svgComponents.sidePanel.maxWidth +
      svgComponents.bodyPanel.maxWidth +
      svgComponents.basePanel.maxWidth,
    4 * patternParameters.spacing +
      svgComponents.sidePanel.maxWidth +
      svgComponents.shoulderStrap.maxWidth +
      (svgComponents.frontPocket?.maxWidth || 0),
    3 * patternParameters.spacing +
      (svgComponents.laptopSleeve?.maxWidth || 0) +
      (svgComponents.dropPockets?.maxWidth || 0)
  );

  const maxSvgWidth = totalWidth;
  const maxSvgHeight = totalHeight;

  const svgPaths = [];
  const svgText = [];
  /* eslint-disable-next-line no-unused-vars */
  for (const [key, value] of Object.entries(svgComponents)) {
    Array.isArray(value.svgPaths) && svgPaths.push(...value.svgPaths);
    Array.isArray(value.svgText) && svgText.push(...value.svgText);
  }

  return {
    svgPaths,
    svgText,
    maxSvgWidth,
    maxSvgHeight,
  };
}

export { backpackPatternSvg };
