import EditorForm from "../../reactComponents/EditorForm";
import EditorPreview from "../../reactComponents/EditorPreview";
import "./EditPage.scss";
import { useState } from "react";

function EditPage() {
  const [projectParameters, setProjectParameters] = useState({
    height: 0.45,
    width: 0.25,
    depth: 0.15,
    topCornerRadius: 0.1,
    bottomCornerRadius: 0.05,
    frontPocket: "none",
    selectLaptopSleeve: false,
    selectDumpPockets: false,
  });

  const [readyToDownload, setReadyToDownload] = useState(false);

  const handleGeneratePDF = () => {
    setReadyToDownload(true);
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    type === "range" &&
      setProjectParameters({
        ...projectParameters,
        [name]: Number(value),
      });

    type === "radio" &&
      setProjectParameters({
        ...projectParameters,
        [name]: value,
      });

    type === "checkbox" &&
      setProjectParameters({
        ...projectParameters,
        [name]: !projectParameters[name],
      });

    setReadyToDownload(false);
  };

  let safeTopRadius;
  if (projectParameters.topCornerRadius > projectParameters.width / 2) {
    safeTopRadius = projectParameters.width / 2;
  } else {
    safeTopRadius = projectParameters.topCornerRadius;
  }

  const effectiveProjectParameters = {
    height: projectParameters.height,
    width: projectParameters.width,
    depth: projectParameters.depth,
    topCornerRadius: safeTopRadius,
    bottomCornerRadius: projectParameters.bottomCornerRadius,
    frontPocket: projectParameters.frontPocket,
    selectLaptopSleeve: projectParameters.selectLaptopSleeve,
    selectDumpPockets: projectParameters.selectDumpPockets,
  };

  return (
    <main className="edit-page">
      <div className="edit-page__container">
        <EditorPreview
          projectParameters={effectiveProjectParameters}
          handleGeneratePDF={handleGeneratePDF}
          readyToDownload={readyToDownload}
        />
        <EditorForm
          projectParameters={effectiveProjectParameters}
          handleChange={handleChange}
        />
      </div>
    </main>
  );
}

export default EditPage;
