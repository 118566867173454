import CONSTANTS from "../../constants";

function backpackPatternParameters(projectParameters) {
  const { height, width, depth, topCornerRadius, bottomCornerRadius } =
    projectParameters;

  // SVGs use points as unit. need to convert metric to pts
  const patternParameters = {
    height: height * CONSTANTS.mToPt,
    heightSA: height * CONSTANTS.mToPt + 2 * CONSTANTS.seamAllowancePt,
    width: width * CONSTANTS.mToPt,
    widthSA: width * CONSTANTS.mToPt + 2 * CONSTANTS.seamAllowancePt,
    depth: depth * CONSTANTS.mToPt,
    depthSA: depth * CONSTANTS.mToPt + 2 * CONSTANTS.seamAllowancePt,
    topCornerRadius: topCornerRadius * CONSTANTS.mToPt,
    topCornerRadiusSA:
      topCornerRadius * CONSTANTS.mToPt + CONSTANTS.seamAllowancePt,
    bottomCornerRadius: bottomCornerRadius * CONSTANTS.mToPt,
    bottomCornerRadiusSA:
      bottomCornerRadius * CONSTANTS.mToPt + CONSTANTS.seamAllowancePt,
    innerHeight:
      (height - topCornerRadius - bottomCornerRadius) * CONSTANTS.mToPt,
    innerWidthTop: (width - 2 * topCornerRadius) * CONSTANTS.mToPt,
    innerWidthBottom: (width - 2 * bottomCornerRadius) * CONSTANTS.mToPt,
    spacing: 5 * CONSTANTS.cmToPt,
  };

  return patternParameters;
}
export default backpackPatternParameters;
