import CONSTANTS from "../../../constants";

const verticalPocketSvg = ({ patternParameters }) => {
  const {
    height,
    widthSA,
    width,
    bottomCornerRadiusSA,
    innerWidthBottom,
    bottomCornerRadius,
    innerWidthTop,
    topCornerRadius,
    topCornerRadiusSA,
    innerHeight,
    heightSA,
  } = patternParameters;

  const svgPaths = [];
  const svgText = [];

  // Seam Allowance
  svgPaths.push(
    [
      `m 0 0`,
      `l ${-innerWidthTop / 2} 0`,
      `a ${topCornerRadiusSA} ${topCornerRadiusSA} 0 0 0 -${topCornerRadiusSA} ${topCornerRadiusSA}`,
      `l 0 ${innerHeight}`,
      `a ${bottomCornerRadiusSA} ${-bottomCornerRadiusSA} 0 0 0 ${bottomCornerRadiusSA} ${bottomCornerRadiusSA}`,
      `l ${innerWidthBottom / 2} 0`,
      `l ${width / 4 + CONSTANTS.seamAllowancePt} 0`,
      `l 0 -${heightSA}`,
      `l -${width / 4 + CONSTANTS.seamAllowancePt} 0`,
    ].join(" ")
  );

  // Main shape
  svgPaths.push(
    [
      `m 0 ${CONSTANTS.seamAllowancePt}`,
      `l ${-innerWidthTop / 2} 0`,
      `a ${topCornerRadius} ${topCornerRadius} 0 0 0 -${topCornerRadius} ${topCornerRadius}`,
      `l 0 ${innerHeight}`,
      `a ${bottomCornerRadius} ${-bottomCornerRadius} 0 0 0 ${bottomCornerRadius} ${bottomCornerRadius}`,
      `l ${innerWidthBottom / 2} 0`,
      `l ${width / 4} 0`,
      `l 0 -${height}`,
      `l -${width / 4} 0`,
    ].join(" ")
  );

  // label text - top
  svgText.push({
    x: -0.125 * widthSA,
    y: height / 2,
    label: "Vertical Pocket",
  });

  return {
    svgPaths,
    svgText,
    maxHeight: heightSA,
    maxWidth: 0.75 * width + 2 * CONSTANTS.seamAllowancePt,
  };
};

export default verticalPocketSvg;
