import PdfGenerator from "../PdfGenerator/PdfGenerator";
import { backpackPatternSvg } from "../../projectPatterns/backpack/backpackPatternSvg";
import "./PreviewPattern.scss";

function PreviewPattern({
  projectParameters,
  handleGeneratePDF,
  readyToDownload,
}) {
  const { svgPaths, svgText, maxSvgWidth, maxSvgHeight } =
    backpackPatternSvg(projectParameters);
  const viewSize = `0 0 ${maxSvgWidth} ${maxSvgHeight}`;

  return (
    <div className="preview-pattern">
      <div className="preview-pattern__svg-container">
        <svg viewBox={viewSize} overflow="auto" data-testid="svg-preview">
          <g>
            {svgPaths.map((path) => (
              <path d={path} stroke="black" strokeWidth="2" fill="white" />
            ))}
          </g>
          <g>
            {svgText.map((text) => (
              <g transform={`translate(${text.x}, ${text.y})`}>
                <text
                  textAnchor="middle"
                  {...(text.verticalAlign ? { transform: "rotate(-90)" } : {})}
                  className="preview-pattern__svg-label"
                >
                  {text.label}
                </text>
              </g>
            ))}
          </g>
        </svg>
      </div>
      <div className="preview-pattern__footer">
        {!readyToDownload ? (
          <span
            className="preview-pattern__download-option"
            onClick={handleGeneratePDF}
          >
            Generate PDF
          </span>
        ) : (
          <PdfGenerator
            svgPaths={svgPaths}
            svgText={svgText}
            maxSvgWidth={maxSvgWidth}
            maxSvgHeight={maxSvgHeight}
          />
        )}
      </div>
    </div>
  );
}

export default PreviewPattern;
