import CONSTANTS from "../../../constants";

const squarePocketSvg = ({ patternParameters }) => {
  const { innerHeight, widthSA, width } = patternParameters;

  const svgPaths = [];
  const svgText = [];

  const standardPocketHeight = 10 * CONSTANTS.cmToPt;
  let squarePocketHeight;
  if (innerHeight < standardPocketHeight) {
    squarePocketHeight = innerHeight;
  } else {
    squarePocketHeight = standardPocketHeight;
  }
  const squarePocketHeightBottomSA =
    squarePocketHeight + 2 * CONSTANTS.seamAllowancePt;
  const squarePocketHeightTop = 8 * CONSTANTS.cmToPt;
  const squarePocketHeightTopSA =
    squarePocketHeightTop + 2 * CONSTANTS.seamAllowancePt;
  const squarePocketDepthSA = 5 * CONSTANTS.cmToPt;
  const squarePocketBottomStartY =
    squarePocketHeightTopSA + 5 * CONSTANTS.cmToPt;

  // Top - Seam Allowance
  svgPaths.push(
    [
      `m 0 ${squarePocketDepthSA}`,
      `l ${squarePocketDepthSA} 0`,
      `l 0 -${squarePocketDepthSA}`,
      `m -${squarePocketDepthSA} 0`,
      `l 0 ${squarePocketHeightTopSA}`,
      `l ${widthSA} 0`,
      `l 0 -${squarePocketHeightTopSA}`,
      `m -${squarePocketDepthSA} 0`,
      `l 0 ${squarePocketDepthSA}`,
      `l ${squarePocketDepthSA} 0`,
      `m 0 -${squarePocketDepthSA}`,
      `l -${widthSA} 0`,
    ].join(" ")
  );

  // Top - Main shape
  svgPaths.push(
    [
      `m ${CONSTANTS.seamAllowancePt} ${CONSTANTS.seamAllowancePt}`,
      `l 0 ${squarePocketHeightTop}`,
      `l ${width} 0`,
      `l 0 -${squarePocketHeightTop}`,
      `l -${width} 0`,
    ].join(" ")
  );

  // Bottom - Seam Allowance
  svgPaths.push(
    [
      `m 0 ${squarePocketBottomStartY}`,
      `l 0 ${squarePocketHeightBottomSA}`,
      `m ${squarePocketDepthSA} 0`,
      `l 0 -${squarePocketDepthSA}`,
      `l -${squarePocketDepthSA} 0`,
      `m 0 ${squarePocketDepthSA}`,
      `l ${widthSA} 0`,
      `m 0 -${squarePocketDepthSA}`,
      `l -${squarePocketDepthSA} 0`,
      `l 0 ${squarePocketDepthSA}`,
      `m ${squarePocketDepthSA} 0`,
      `l 0 -${squarePocketHeightBottomSA}`,
      `l -${widthSA} 0`,
    ].join(" ")
  );

  // Bottom - Main shape
  svgPaths.push(
    [
      `m ${CONSTANTS.seamAllowancePt} ${
        squarePocketBottomStartY + CONSTANTS.seamAllowancePt
      }`,
      `l 0 ${squarePocketHeight}`,
      `l ${width} 0`,
      `l 0 -${squarePocketHeight}`,
      `l -${width} 0`,
    ].join(" ")
  );

  // label text - top
  svgText.push({
    x: widthSA / 2,
    y: squarePocketHeightTopSA / 2,
    label: "Square Pocket - Top",
  });

  // label text - bottom
  svgText.push({
    x: widthSA / 2,
    y: squarePocketBottomStartY + squarePocketHeightBottomSA / 2,
    label: "Square Pocket - Bottom",
  });

  return {
    svgPaths,
    svgText,
    maxHeight:
      squarePocketHeightTopSA +
      squarePocketHeightBottomSA +
      5 * CONSTANTS.cmToPt,
    maxWidth: widthSA,
  };
};

export default squarePocketSvg;
