import CONSTANTS from "../../../constants";

const shoulderStrapSvg = ({ patternParameters }) => {
  const { height } = patternParameters;

  const svgPaths = [];
  const svgText = [];

  const shoulderStrapWidth = 12 * CONSTANTS.cmToPt;
  const shoulderStrapWidthSA =
    shoulderStrapWidth + 2 * CONSTANTS.seamAllowancePt;
  const shoulderStrapLength =
    height - 5 < 42 * CONSTANTS.cmToPt
      ? height - 5 * CONSTANTS.cmToPt
      : 42 * CONSTANTS.cmToPt;
  const shoulderStrapLengthSA =
    shoulderStrapLength + 2 * CONSTANTS.seamAllowancePt;

  // Seam Allowance
  svgPaths.push(
    [
      `m 0 0`,
      `l 0 ${shoulderStrapLengthSA}`,
      `l ${shoulderStrapWidthSA} 0`,
      `l 0 -${shoulderStrapLengthSA}`,
      `l -${shoulderStrapWidthSA} 0`,
    ].join(" ")
  );

  // Main shape
  svgPaths.push(
    [
      `m ${CONSTANTS.seamAllowancePt} ${CONSTANTS.seamAllowancePt}`,
      `l 0 ${shoulderStrapLength}`,
      `l ${shoulderStrapWidth} 0`,
      `l 0 -${shoulderStrapLength}`,
      `l -${shoulderStrapWidth} 0`,
    ].join(" ")
  );

  // label text
  svgText.push({
    x: shoulderStrapWidthSA / 2,
    y: shoulderStrapLengthSA / 2,
    label: "Shoulder Strap",
  });

  return {
    svgPaths,
    svgText,
    maxHeight: shoulderStrapLengthSA,
    maxWidth: shoulderStrapWidthSA,
  };
};

export default shoulderStrapSvg;
