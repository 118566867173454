import * as THREE from "three";

function HorizontalPocket({ start = [0, 0], ...props }) {
  if (!props.shouldDisplay) {
    return <mesh />;
  }

  const { bottomLeftLow, bottomRightLow, bottomCornerRadius, width, height } =
    props;

  const pocketShape = new THREE.Shape();

  pocketShape.moveTo(bottomRightLow[0], bottomRightLow[1]);
  // bottom right arc:
  pocketShape.arc(0, bottomCornerRadius, bottomCornerRadius, -1.57, 0, false);
  // right vertical side:
  pocketShape.lineTo(width / 2, height / 2);
  // top horizontal:
  pocketShape.lineTo(-width / 2, height / 2);
  // left vertical:
  pocketShape.lineTo(bottomLeftLow[0], bottomLeftLow[1]);
  // bottom right arc:
  pocketShape.arc(
    bottomCornerRadius,
    0,
    bottomCornerRadius,
    -3.142,
    -1.57,
    false
  );
  // bottom horizontal:
  pocketShape.lineTo(bottomRightLow[0], bottomRightLow[1]);

  const extrudeSettings = {
    steps: 2,
    depth: 0.01,
    bevelEnabled: false,
  };

  const pocketGeometry = new THREE.ExtrudeGeometry(
    pocketShape,
    extrudeSettings
  );
  const pocketEdges = new THREE.EdgesGeometry(pocketGeometry, 45);

  const pocketZipperShape = new THREE.Shape();

  pocketZipperShape.moveTo(width / 2, 0);
  pocketZipperShape.lineTo(width / 2, 0.01);
  pocketZipperShape.lineTo(-width / 2, 0.01);
  pocketZipperShape.lineTo(-width / 2, 0);
  pocketZipperShape.lineTo(width / 2, 0);

  const pocketZipperGeometry = new THREE.ShapeGeometry(pocketZipperShape);

  return (
    <group position={props.position} dispose={null}>
      <mesh geometry={pocketGeometry}>
        <meshStandardMaterial />
      </mesh>
      <lineSegments geometry={pocketEdges}>
        <lineBasicMaterial color="black" depthTest={true} />
      </lineSegments>
      <mesh
        geometry={pocketZipperGeometry}
        rotation={[Math.PI / 2, 0, 0]}
        position={[0, height / 2 + 0.0001, 0]}
      >
        <meshStandardMaterial side={THREE.DoubleSide} color="#000000" />
      </mesh>
    </group>
  );
}

export default HorizontalPocket;
