import "./EditorPreview.scss";
import PreviewModel from "../PreviewModel";
import PreviewPattern from "../PreviewPattern";
import { useState } from "react";

function EditorPreview({
  projectParameters,
  readyToDownload,
  handleGeneratePDF,
}) {
  const [previewMode, setPreviewMode] = useState("model");
  const [viewMode, setViewMode] = useState("exterior");

  const handleChangePreviewMode = (previewMode) => {
    setPreviewMode(previewMode);
  };

  const handleChangeViewMode = (viewMode) => {
    setViewMode(viewMode);
  };

  return (
    <div className="editor-preview">
      <header className="editor-preview__header">
        <div
          className={
            previewMode === "model"
              ? "editor-preview__tab editor-preview__tab--active"
              : "editor-preview__tab"
          }
        >
          <span
            className="editor-preview__tab-title"
            onClick={() => {
              handleChangePreviewMode("model");
            }}
          >
            Preview
          </span>
        </div>
        <div
          className={
            previewMode === "pattern"
              ? "editor-preview__tab editor-preview__tab--active"
              : "editor-preview__tab"
          }
        >
          <span
            className="editor-preview__tab-title"
            onClick={() => {
              handleChangePreviewMode("pattern");
            }}
          >
            Pattern
          </span>
        </div>
      </header>
      {previewMode === "model" ? (
        <PreviewModel
          projectParameters={projectParameters}
          handleChangeViewMode={handleChangeViewMode}
          viewMode={viewMode}
        />
      ) : (
        ""
      )}
      {previewMode === "pattern" ? (
        <PreviewPattern
          projectParameters={projectParameters}
          handleGeneratePDF={handleGeneratePDF}
          readyToDownload={readyToDownload}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default EditorPreview;
