import CONSTANTS from "../../../constants";

const laptopSleeveSvg = ({ patternParameters }) => {
  const { width, innerHeight } = patternParameters;

  const svgPaths = [];
  const svgText = [];

  const laptopSleeveWidthSA = width + 2 * CONSTANTS.seamAllowancePt;
  const laptopSleeveHeightSA = innerHeight + CONSTANTS.seamAllowancePt;

  // Seam Allowance
  svgPaths.push(
    [
      `m 0 0`,
      `l 0 ${laptopSleeveHeightSA}`,
      `l ${laptopSleeveWidthSA} 0`,
      `l 0 -${laptopSleeveHeightSA}`,
      `l -${laptopSleeveWidthSA} 0`,
    ].join(" ")
  );

  // Main shape
  svgPaths.push(
    [
      `m ${CONSTANTS.seamAllowancePt} 0`,
      `l 0 ${innerHeight}`,
      `l ${width} 0`,
      `l 0 -${innerHeight}`,
      `l -${width} 0`,
    ].join(" ")
  );

  // label text - top
  svgText.push({
    x: laptopSleeveWidthSA / 2,
    y: innerHeight / 2,
    label: "Laptop Sleeve",
  });

  return {
    svgPaths,
    svgText,
    maxHeight: laptopSleeveHeightSA,
    maxWidth: laptopSleeveWidthSA,
  };
};

export default laptopSleeveSvg;
